import Layout from '../../components/layouts/freshest/FreshestLayout';
import AppStorePage from '../../components/appstore/AppStorePage';
import useAuthRedirect from '../../components/useAuthRedirect';

const AppStoreTemplate = () => {

    useAuthRedirect();

    return (
        <Layout pageTitle='AppStore' className='appstoreLayout' pageDescription={'Apps that will allow you to interact with MetaDefender Cloud '}>
            <AppStorePage />
        </Layout>
    );
};


export default AppStoreTemplate;
