import { useEffect, useState, useContext } from 'react';
import { UserContext } from '@mdc/ui';
import Cookie from 'js-cookie';
import { COOKIE_OPSWAT_REDIRECT } from '@mdc/constants';

const useAuthRedirect = () => {
    const userContext = useContext(UserContext);
    const [loggedIn, setLoggedIn] = useState(false);
    const [windowLocation, setWindowLocation] = useState({ href: '/' });

    useEffect(() => {
        if (userContext.state === userContext.STATES.LOGGED_OUT) {
            // If user hasn't log in yet, should redirect after login
            // If user logged in, then logout, don't need to redirect again (redirect to homepage instead)
            Cookie.set(COOKIE_OPSWAT_REDIRECT, loggedIn ? '' : windowLocation.href, {
                path: '/',
                secure: true,
            });
            window.location.href = '/login';
            return;
        }

        if (userContext.state === userContext.STATES.LOGGED_IN) {
            setLoggedIn(true);
        }

    }, [userContext, loggedIn]);

    useEffect(() => {
        setWindowLocation(window && window.location);
    }, []);

    return { loggedIn, windowLocation };
};

export default useAuthRedirect;
