import { Children, cloneElement, useContext, useMemo } from 'react';
import { UploadPreloaderContext, ThemeProvider, InternalNavigation } from '@mdc/services';
import { COOKIE_OPSWAT_REDIRECT } from '@mdc/constants';
import { CookieBanner, useCookieBannerState, NotificationContext, DropZoneContext, Header, Footer, UserContext, ScrollTopButton, NetPromoterScoreManager, useAnnouncementBannerState, InternalLink } from '@mdc/ui';
import ScanComponent from '../../scan-component/ScanComponent';
import { Container, Row } from 'react-bootstrap';
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
import Seo from '../../seo';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import FooterNavigation from '../../footer-navigation/FooterNavigation';

import './FreshestLayout.scss';

const FreshestLayout = ({ children, pageTitle, pageDescription, className, sitemapURL, scanError, hasAnnouncementBanner = true, announcementBannerContent }) => {
    const { t, ready } = useTranslation();
    const [isCookieBannerVisible] = useCookieBannerState();
    const { notify } = useContext(NotificationContext);
    const { onFileDrop } = useContext(DropZoneContext);
    const uploadPreloader = useContext(UploadPreloaderContext);
    const userContext = useContext(UserContext);
    const [isAnnouncementBannerVisible] = useAnnouncementBannerState();

    const gatsbyLinks = {
        store: <InternalLink to='/store'>{t('Store')}</InternalLink>,
        leaderboard: <InternalLink to='/leaderboard'>{t('Leaderboard')}</InternalLink>,
        appstore: <InternalLink to='/appstore'>{t('AppStore')}</InternalLink>
    };

    const onLicensingClick = () => {
        InternalNavigation.navigation('/licensing');
    };

    const theme = [{ name: 'freshest' }];

    const onSignInClicked = () => {
        if (userContext.state === userContext.STATES.LOGGED_IN) {
            return;
        }

        Cookie.set(COOKIE_OPSWAT_REDIRECT, window.location.href, { secure: true });
        window.location.href = '/login';
    };

    const childrenWithProps = Children.map(children, (child) => {
        return cloneElement(child, { notify, onFileDrop, uploadPreloader });
    });

    const hasAnnouncementBannerCondition = useMemo(() => {
        if (isAnnouncementBannerVisible) {
            return hasAnnouncementBanner;
        }

        return hasAnnouncementBanner ? isAnnouncementBannerVisible : false;
    }, [announcementBannerContent, isAnnouncementBannerVisible, hasAnnouncementBanner]);


    const layoutClassnames = classnames({
        withCookieBanner: isCookieBannerVisible,
        withAnnouncementBanner: hasAnnouncementBannerCondition
    }, 'freshestLayout', className);

    const headerClassnames = classnames('headerRow');
    const footerNavigation = <FooterNavigation />;

    if (!ready) {
        return;
    }

    return (
        <ThemeProvider themes={theme}>
            <Container fluid className={layoutClassnames}>
                {hasAnnouncementBanner && announcementBannerContent}
                <Row className={headerClassnames}>
                    <Seo title={pageTitle} description={pageDescription} theme="freshest" sitemapURL={sitemapURL} />
                    <Header
                        useGlassEffect
                        onSignIn={onSignInClicked}
                        onLicensingClick={onLicensingClick}
                        gatsbyLinks={gatsbyLinks}
                    >
                        <ScanComponent
                            onFileDrop={onFileDrop}
                            notify={notify}
                            showText={false}
                            scanError={scanError}
                        />
                    </Header>
                </Row>
                <Row>
                    <Container className="pageContent">
                        {childrenWithProps}
                    </Container>
                </Row>
                <Row>
                    <CookieBanner />
                    <NetPromoterScoreManager />
                </Row>
                <Row>
                    <Footer navigationItems={footerNavigation} />
                </Row>
            </Container>
            <ScrollTopButton />
        </ThemeProvider>
    );
};

FreshestLayout.propTypes = {
    children: PropTypes.element.isRequired,
    notify: PropTypes.func,
    pageTitle: PropTypes.string.isRequired,
    pageDescription: PropTypes.string,
    className: PropTypes.string,
    sitemapURL: PropTypes.string,
    scanError: PropTypes.any,
    hasAnnouncementBanner: PropTypes.bool,
    announcementBannerContent: PropTypes.node
};

export default FreshestLayout;
