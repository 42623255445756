/* eslint-disable security/detect-non-literal-fs-filename */
/* detect-non-literal-fs-filename */
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import PRODUCTS from './appStore.data';

import './AppStore.scss';

const AppStorePage = () => {
    const { t, ready } = useTranslation();

    const generateCard = (name, title, description, link) => {
        return ( <Col lg={4} md={6} sm={12}>
            <Col className='storeCard'>
                <h2 className='cardName'> {name} </h2>
                <p className='cardTitle'> {title} </p>
                <p className='cardDescription'> {description} </p>
                <div className='cardButton'>
                    <Button
                        className="button accept"
                        variant="primary"
                        href={link}
                        target="_blank"
                    >
                        <span> <i className='gitIcon'/> {t('Get it on GitHub')} </span>
                    </Button>
                </div>
            </Col>
        </Col>);

    };

    const extensionSection = PRODUCTS(t).filter((item) => item.type === 'extension').map((item) => {
        return generateCard(item.name, item.title, item.description, item.link);
    });

    const clientSection = PRODUCTS(t).filter((item) => item.type === 'client').map((item) => {
        return generateCard(item.name, item.title, item.description, item.link);
    });

    const desktopSection = PRODUCTS(t).filter((item) => item.type === 'desktop').map((item) => {
        return generateCard(item.name, item.title, item.description, item.link);
    });

    if (!ready) {
        return null;
    }

    return (
        <section className='appstoreWrapper'>
            <Col>
                <Row>
                    <Col sm={{ span: 10, order: 0 }} xs={{ span: 12, order: 1 }} className="description pl-0 ml-0">
                        <h1>{t('AppStore')}</h1>
                        <p>
                            {t('MetaDefender Cloud is driving the Community in order to utilize MetaDefender Cloud\'s API to upload and scan files or URLs, access finished scan reports and contribute towards prevention of malicious threats that will make the Internet a safer place, in other words, it allows you to build simple scripts to access the information generated by MetaDefender Cloud that help the antivirus industry in receiving more files in order to have more visibility into threats. Below you can find links to apps that will allow you to interact with MetaDefender Cloud making use of several client-side tools to help users more seamlessly interact with the MetaDefender Cloud service. There are flavors of these tools for various personas, refer to the section that best suits you')}
                        </p>
                    </Col>
                </Row>
                <Row className='section'>
                    <span><i className='extensionIcon'/> <p className='text'> {t('Browser Extensions')}</p></span>
                </Row>
                <Row> {extensionSection} </Row>
                <Row className='section'>
                    <span><i className='clientIcon'/> <p className='text'>{t('Client Agents')}</p></span>
                </Row>
                <Row className='cardSection'>{clientSection}</Row>
                {/* will not display Processs Hacker until it ready */}
                <Row className='section' style={{ display: 'none' }}>
                    <span><i className='desktopIcon'/> <p className='text'>{t('Desktop Apps')}</p></span>
                </Row>
                <Row style={{ display: 'none' }}>{desktopSection}</Row>
            </Col>
        </section>
    );
};


export default AppStorePage;
