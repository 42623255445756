
export default (t) => {
    return [
        {
            name: 'metadefender-browser-extension',
            title: t('AppSec - Browser Extensions'),
            description: t('Browser extension for scanning with MetaDefender'),
            type: 'extension',
            link: 'https://chrome.google.com/webstore/detail/opswat-file-security-for/fjampemfhdfmangifafmianhokmpjbcj'
        },
        {
            name: 'metadefender-gatling-test',
            title: t('Test Automation - Client Agent'),
            description: t('Using Gatling framework to test MetaDefender Core performance'),
            type: 'client',
            link: 'https://github.com/OPSWAT/metadefender-gatling-test'
        },
        {
            name: 'metadefender-teamcity-plugin',
            title: t('DevSecOPs - Client Agent'),
            description: t('MetaDefender TeamCity plugin'),
            type: 'client',
            link: 'https://github.com/OPSWAT/metadefender-teamcity-plugin'

        },
        {
            name: 'metadefender-menlo-integration',
            title: t('AppSec - Client Agent'),
            description: t('MetaDefender integration to Menlo Security'),
            type: 'client',
            link: 'https://github.com/OPSWAT/metadefender-menlo-integration'
        },
        {
            name: 'metadefender-cloudformation',
            title: t('DevOPs - Client Agent'),
            description: t('CloudFormation Templates to generate all resources needed to run the MetaDefender AMI'),
            type: 'client',
            link: 'https://github.com/OPSWAT/metadefender-cloudformation'
        },
        {
            name: 'mdcloud-go',
            title: t('AppSec - Client Agent'),
            description: t('MetaDefender Cloud CLI in GOLang'),
            type: 'client',
            link: 'https://github.com/OPSWAT/mdcloud-go'
        },
        {
            name: 'Salesforce App',
            title: t('AppSec - Client Agent'),
            description: t('OPSWAT Cloud Security for Salesforce'),
            type: 'client',
            link: 'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000FeG3eUAF'
        },
        {
            name: 'Process Hacker',
            title: t('AppSec - Desktop Apps'),
            description: t('Monitor system resources, debug software and detect malware'),
            type: 'desktop',
            link: '/'
        },
    ];
};
